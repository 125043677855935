import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

const secure = '(/secure/|/)';
const defaultSuffix = '([!%]?[\\w]*)'; // like !default / !edit / %21viewLogs

export const legacyProjectSettingsDeleteRoute: InvariantRoute = {
	name: 'legacy-project-settings-delete',
	path: `${secure}project/DeleteProject${defaultSuffix}.jspa`,
	query: ['pid'],
};
