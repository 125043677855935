import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import {
	EntryKeys,
	ENTRYPOINT_ID_JIRA_PLATFORM,
} from '@atlassian/jira-feedback-collector/src/constants.tsx';
import { useFeedbackCollectorActions } from '@atlassian/jira-feedback-collector/src/controllers/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import normaliseUrl from '@atlassian/jira-platform-url-normalisation/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import messages from './messages.tsx';
import type { AdditionalEntries, OpenFeedback, Props } from './types.tsx';

const unknownAttributeValue = 'unknown';

const FEEDBACK_GROUP_ID = 'workflow-editor-opt-out';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FeedbackTitleDetailsContainer = styled.div({
	marginBottom: token('space.200'),
});

export const getAdditionalFeedbackEntries = ({
	isSiteAdmin,
	routeName,
	projectType,
	cloudId,
}: AdditionalEntries) => [
	{
		id: EntryKeys.LOCATION,

		value: fg('jfp-magma-undefined-navigator')
			? normaliseUrl(globalThis.window?.location.href)
			: normaliseUrl(window.location.href), // eslint-disable-line jira/jira-ssr/no-unchecked-globals-usage
	},
	{
		id: EntryKeys.REFERER,

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		value: document.referrer ? normaliseUrl(document.referrer) : '',
	},
	{
		id: EntryKeys.USER_AGENT,

		value: fg('jfp-magma-undefined-navigator')
			? globalThis.navigator?.userAgent
			: navigator.userAgent, // eslint-disable-line jira/jira-ssr/no-unchecked-globals-usage
	},
	{
		id: EntryKeys.SCREEN_RESOLUTION,

		value: fg('jfp-magma-undefined-navigator')
			? `${globalThis.window?.screen?.width} x ${globalThis.window?.screen?.height}`
			: `${window.screen.width} x ${window.screen.height}`, // eslint-disable-line jira/jira-ssr/no-unchecked-globals-usage
	},
	{
		id: EntryKeys.ROUTE_NAME,
		value: routeName,
	},
	{
		id: EntryKeys.IS_ADMIN,
		value: isSiteAdmin.toString(),
	},
	{
		id: EntryKeys.PROJECT_TYPE,
		value: projectType,
	},
	{
		id: 'cloud-id',
		value: cloudId,
	},
];

export const useOptOutFeedbackDialog = ({
	onClose = () => undefined,
	projectType,
}: Props): OpenFeedback => {
	const { formatMessage } = useIntl();

	const { openFeedbackCollector } = useFeedbackCollectorActions();
	const route = useCurrentRoute();
	const { cloudId, isSiteAdmin } = useTenantContext();

	const routeName = route.name ?? unknownAttributeValue;

	const renderFeedbackDialog = useCallback(() => {
		openFeedbackCollector({
			entrypointId: ENTRYPOINT_ID_JIRA_PLATFORM,
			feedbackGroupId: FEEDBACK_GROUP_ID,
			onClose,
			feedbackContext: getAdditionalFeedbackEntries({
				isSiteAdmin,
				cloudId,
				routeName,
				projectType: projectType || unknownAttributeValue,
			}),
			feedbackGroupLabels: {
				empty: {
					selectOptionLabel: formatMessage(messages.reasonPlaceholder),
				},
				bug: {
					fieldLabel: formatMessage(messages.fieldLabel),
					selectOptionLabel: formatMessage(messages.reasonBug),
				},
				comment: {
					fieldLabel: formatMessage(messages.fieldLabel),
					selectOptionLabel: formatMessage(messages.reasonComment),
				},
				suggestion: {
					fieldLabel: formatMessage(messages.fieldLabel),
					selectOptionLabel: formatMessage(messages.reasonOthers),
				},
				question: {
					fieldLabel: formatMessage(messages.fieldLabel),
					selectOptionLabel: formatMessage(messages.reasonQuestion),
				},
			},
			feedbackTitleDetails: (
				<FeedbackTitleDetailsContainer>
					{formatMessage(messages.feedbackTitleDetails)}
				</FeedbackTitleDetailsContainer>
			),
			selectLabel: formatMessage(messages.feedbackTitleDetailsQuestion),
			cancelButtonLabel: formatMessage(messages.feedbackCancelButton),
		});
	}, [formatMessage, openFeedbackCollector, onClose, isSiteAdmin, cloudId, routeName, projectType]);

	return { renderFeedbackDialog };
};
