import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

const secure = '(/secure/|/)';
const defaultSuffix = '([!%]?[\\w]*)'; // like !default / !edit / %21viewLogs

export const legacyProjectSettingsEditRoute: InvariantRoute = {
	name: 'legacy-project-settings-edit',
	path: `${secure}project/EditProject${defaultSuffix}.jspa`,
	query: ['pid'],
};
